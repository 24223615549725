<template>
  <a-spin :spinning="loading">
    <div>
      <div class="serviceHeader">
        <div class="font-size-36 font-weight-bold text-dark mb-4">
          Transaction
        </div>
      </div>

      <div class="flex align-items-center w-100">
        <a-card hoverable style="width: 70%">
          <a-row type="flex" class="flex-column m-5">
            <!-- profile -->
            <a-row
              type="flex"
              class="t-profile-item border-bottom border-1 justify-content-between"
            >
              <a-row
                type="flex"
                class="t-profile-header flex-column justify-content-between"
              >
                <a-row>
                  <a-col class="t-profile-header-pad"> Name </a-col>
                  <a-col class="detail">
                    {{ user.first_name + " " + user.last_name }}
                  </a-col>
                </a-row>
                <a-row>
                  <a-col class="t-profile-header-pad"> Phone Number </a-col>
                  <a-col class="detail">
                    {{ user.phone }}
                  </a-col>
                </a-row>
                <a-row>
                  <a-col class="t-profile-header-pad">
                    Transaction Number
                  </a-col>
                  <a-col class="detail">
                    {{ transaction.transaction_no }}
                  </a-col>
                </a-row>
                <a-row v-if="transaction.payment_mode == 'cash'">
                  <a-col class="t-profile-header-pad"> Payment Code </a-col>
                  <a-col class="detail">
                    {{ transaction.payment_code }}
                  </a-col>
                </a-row>
              </a-row>
              <a-row
                type="flex"
                class="align-items-center justify-content-end"
                style="width: 50%"
              >
                <div
                  class="kit__utils__avatar kit__utils__avatar--size110 kit__utils__avatar--rounded image-fill"
                >
                  <img :src="userImage" class="image-fill" />
                </div>
              </a-row>
            </a-row>

            <!-- date -->
            <a-row
              type="flex"
              class="t-profile-item border-bottom border-1 flex-column justify-content-between"
            >
              <a-row
                type="flex"
                class="t-profile-header justify-content-between"
              >
                <a-col> Date Purchaced </a-col>
                <a-col> Date Scanned </a-col>
              </a-row>
              <a-row type="flex" class="justify-content-between">
                <a-col class="detail">
                  {{ normalizeDate(transaction.created) }}
                </a-col>
                <a-col type="inline-flex" class="detail">
                  {{ normalizeDate(transaction.scanned_date) }}
                </a-col>
              </a-row>
            </a-row>

            <a-row
              type="flex"
              v-if="orders"
              class="t-profile-item border-bottom border-1 flex-column justify-content-between"
            >
              <a-row
                type="flex"
                class="t-profile-header justify-content-between"
              >
                <a-col>
                  {{ serviceText }}
                </a-col>
                <a-col>
                  {{ quantityText }}
                </a-col>
              </a-row>
              <a-row
                type="flex"
                v-for="(order, index) in orders"
                :key="index"
                class="justify-content-between"
              >
                <a-col class="detail">
                  {{
                    order.name ||
                    order.service.title ||
                    order.service.vehicle_model
                  }}
                </a-col>
                <a-col type="inline-flex" class="detail">
                  {{ computeOrderQuantity(order) }}
                </a-col>
              </a-row>
            </a-row>

            <a-row
              type="flex"
              v-if="isForRoom || isForTransportRental"
              class="t-profile-item border-bottom border-1 flex-column justify-content-between"
            >
              <a-row
                type="flex"
                class="t-profile-header justify-content-between"
              >
                <a-col>
                  {{ checkInText }}
                </a-col>
                <a-col>
                  {{ checkOutText }}
                </a-col>
              </a-row>
              <a-row
                type="flex"
                v-for="(order, index) in orders"
                :key="index"
                class="justify-content-between"
              >
                <a-col class="detail">
                  {{ checkin | formatMomentDate }}
                </a-col>
                <a-col type="inline-flex" class="detail">
                  {{ checkout | formatMomentDate }}
                </a-col>
              </a-row>
            </a-row>

            <!-- price -->

            <a-row
              type="flex"
              class="t-profile-item border-bottom border-1 flex-column justify-content-between"
            >
              <a-row
                type="flex"
                class="t-profile-header justify-content-between"
              >
                <a-col> Cost </a-col>
                <a-col> Status </a-col>
              </a-row>

              <a-row type="flex" class="justify-content-between">
                <a-col class="detail">
                  {{ transaction.total_price | formatCurrency }}
                </a-col>
                <a-col type="inline-flex">
                  <a-tag
                    :color="getStatusColor(transaction.status)"
                    style="margin: 0"
                  >
                    {{ transaction.status }}
                  </a-tag>
                </a-col>
              </a-row>
            </a-row>

            <a-row
              type="flex"
              class="justify-content-between align-content-baseline"
              v-if="
                (isForRoom || isForTransportRental) &&
                currentUserType !== 'tourist'
              "
            >
              <p class="text-muted">{{ serviceCheckoutStatusText }}</p>

              <a-popconfirm
                placement="top"
                ok-text="Yes"
                cancel-text="No"
                @confirm="checkoutHandler"
                v-if="!(vehicleReturned || roomCheckedOut)"
              >
                <template slot="title">
                  <p>Are you sure you want to {{ checkOutUserText }}?</p>
                </template>
                <a-button size="small" type="primary">
                  {{ checkOutUserText }}
                </a-button>
              </a-popconfirm>
            </a-row>
          </a-row>
        </a-card>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { GET_TRANSACTION, CHECKOUT_ROOM, RELEASE_CAR } from "@/store/actions";

import defaultImage from "@/assets/images/profile_image.png";
import ProfileMixin from "@/mixins/Profile";
import moment from "moment";

export default {
  mixins: [ProfileMixin],
  data() {
    return {
      showSuccessModal: false,
      showFailureModal: false,
      visible: false,
      transaction: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUserType"]),
    orders() {
      return this.transaction.orders;
    },
    transactionId() {
      const { transaction_id } = this.$route.params;
      return transaction_id;
    },
    user() {
      return this.transaction.user || {};
    },
    entity() {
      return this.transaction.entity || {};
    },
    userImage() {
      return this.user && this.user.avatar ? this.user.avatar : defaultImage;
    },
    isForRoom() {
      const { booking_info } = this.transaction;
      return booking_info && Object.keys(booking_info).length > 0;
    },
    isForTransportRental() {
      const { rental_info } = this.transaction;
      return rental_info && Object.keys(rental_info).length > 0;
    },
    quantityText() {
      if (this.isForRoom) return "Nights";
      return this.isForTransportRental ? "Number of Days" : "Quantity";
    },
    roomCheckedOut() {
      if (this.isForRoom) {
        const { booking_info } = this.transaction;
        return booking_info ? booking_info.checked_out : false;
      }
      return false;
    },
    vehicleReturned() {
      if (this.isForTransportRental) {
        const { rental_info } = this.transaction;
        return rental_info ? rental_info.returned : false;
      }
      return false;
    },
    notCheckedOut() {
      return this.vehicleReturned || this.roomCheckedOut;
    },
    serviceText() {
      if (this.isForRoom) return "Room";
      return this.isForTransportRental ? "Vehicle" : "Service";
    },
    checkin() {
      if (this.isForRoom) {
        const { booking_info } = this.transaction;
        return moment(booking_info.check_in_date);
      }
      if (this.isForTransportRental) {
        const { rental_info } = this.transaction;
        return moment(rental_info.pickup_date);
      }
      return null;
    },
    checkout() {
      if (this.isForRoom) {
        const { booking_info } = this.transaction;
        return moment(booking_info.check_out_date);
      }
      if (this.isForTransportRental) {
        const { rental_info } = this.transaction;
        return moment(rental_info.return_date);
      }
      return null;
    },
    checkInText() {
      return this.isForRoom ? "Check In" : "Pick up";
    },
    checkOutText() {
      return this.isForRoom ? "Check Out" : "Return date";
    },
    checkOutUserText() {
      return this.isForRoom ? "Free room" : "Return Vehicle";
    },
    serviceCheckoutStatusText() {
      if (this.isForTransportRental) {
        return this.vehicleReturned
          ? "Vehicle returned and available for booking"
          : "Vehicle not returned";
      } else if (this.isForRoom) {
        return this.roomCheckedOut ? "CHECKED OUT" : "ROOM STILL OCCUPIED";
      } else return "";
    },
  },
  async mounted() {
    if (this.transactionId) {
      this.transaction = await this.getOneTransaction(this.transactionId);
    }
  },
  methods: {
    ...mapActions("misc", {
      getOneTransaction: GET_TRANSACTION,
    }),
    ...mapActions("transport", {
      releaseCar: RELEASE_CAR,
    }),
    ...mapActions("accommodations", {
      freeRoom: CHECKOUT_ROOM,
    }),
    async checkoutHandler() {
      if (this.currentUserType === "tourist") return;
      try {
        this.loading = true;
        let message = "";
        let description = "";
        if (this.isForRoom) {
          const booking_id = this.transaction.booking_info._id;
          const updatedBookingInfo = await this.freeRoom({ booking_id });
          this.transaction.booking_info = updatedBookingInfo;

          message = "Transaction info updated";
          description = "Room has now been freed and can be booked";
        } else if (this.isForTransportRental) {
          const rental_id = this.transaction.rental_info._id;
          const updatedRentalInfo = await this.releaseCar({ rental_id });
          this.transaction.rental_info = updatedRentalInfo;

          message = "Vehicle released";
          description = "Vehicle is now available for rental";
        }
        this.$notification.success({
          message,
          description,
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: "Error updating transaction detail",
          description: "Uknown error occured while updating transaction info",
        });
      } finally {
        this.loading = false;
      }
    },
    computeOrderQuantity(order) {
      if (this.isForRoom || this.isForTransportRental) {
        return this.checkout && this.checkin
          ? this.checkout.diff(this.checkin, "days")
          : "N/A";
      }
      return order.order_quantity;
    },
    getStatusColor(status) {
      if (status === "success") return "green";
      else if (status === "pending") return "yellow";
      else return "red";
    },
    normalizeDate(date) {
      return date ? new Date(date).toDateString() : "N/A";
    },
  },
};
</script>
<style scoped>
.ant-input.ant-input-disabled {
  background-color: white;
}
.t-profile-item {
  margin-bottom: 20px;
}
.t-profile-header {
  margin-bottom: 15px;
}
.t-profile-header-pad {
  padding: 10px 0;
}
.flex {
  display: flex;
}
.detail {
  color: black;
  font-weight: bold;
}
.image-fit {
  object-fit: cover;
  height: inherit;
  width: inherit;
}
</style>
