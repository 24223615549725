<template>
  <transaction-details></transaction-details>
</template>

<script>
import TransactionDetails from "@/components/transactions/TransactionDetails.vue";

export default {
  components: {
    TransactionDetails,
  },
};
</script>
